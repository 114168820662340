<template>
    <div class="date-range-picker">
        <md-input-container>
            <md-select v-model="range">
                <md-option value="All">All</md-option>
                <md-divider></md-divider>
                <md-option value="Today">Today</md-option>
                <template v-if="showUpcoming">
                    <md-option value="Tomorrow">Tomorrow</md-option>
                    <md-option value="This week">This week</md-option>
                    <md-option value="This month">This month</md-option>
                    <md-option value="Next 7 days">Next 7 days</md-option>
                    <md-option value="Next 30 days">Next 30 days</md-option>
                    <md-option value="All - upcoming">All - upcoming</md-option>
                </template>
                <md-divider></md-divider>
                <md-option value="Yesterday">Yesterday</md-option>
                <md-option value="Last week">Last week</md-option>
                <md-option value="Last 7 days">Last 7 days</md-option>
                <md-option value="Last month">Last month</md-option>
                <md-option value="Last 30 days">Last 30 days</md-option>
                <md-option value="All - past">All - past</md-option>
                <md-divider></md-divider>
                <md-option value="Custom">Custom</md-option>
            </md-select>
        </md-input-container>
        <md-input-container class="range-container" v-if="range=='Custom'">
            <date-picker :date="{time:startDate?startDate.format('YYYY-MM-DD'):''}" :limit="_limit" :option="op" @change="datePickerChange($event,'start')" ref="dp"></date-picker>
            <span>-</span>
            <date-picker :date="{time:endDate?endDate.format('YYYY-MM-DD'):''}" :limit="_limit" :option="op" @change="datePickerChange($event,'end')" ref="dp"></date-picker>
        </md-input-container>
    </div>
</template>

<script>
import moment from "moment-timezone";
export default {
    props: {
        value: {
            type: Array | String, //[moment,moment] || 'Today'
            default: "All"
        },
        showUpcoming: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            inputEmiting: false,
            range: "",
            startDate: null,
            endDate: null
        };
    },
    computed: {
        op() {
            return {
                type: "day",
                placeholder: "YYYY-MM-DD",
                week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
                month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                format: "YYYY-MM-DD"
            };
        },
        _limit() {
            var unavailTime = [];

            return this.limit || unavailTime;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(n, o) {
                if (typeof this.value == "object" && this.value.length) {
                    if (moment(this.value[0]).diff(moment(this.startDate)) != 0 || moment(this.value[1]).diff(moment(this.endDate)) != 0) {
                        this.startDate = this.value[0] && moment(this.value[0]);
                        this.endDate = this.value[1] && moment(this.value[1]);
                        setTimeout(() => {
                            this.range = this.range || "Custom";
                        }, 0);
                    }
                } else if (typeof this.value == "string") {
                    setTimeout(() => {
                        if (typeof this.value == "string") {
                            this.range = this.value;
                        }
                    }, 0);
                }
            }
        },
        range: {
            // immediate: true,
            handler(n, o) {
                console.info("range changed", this.range);
                let begin = null;
                let end = null;
                let today = () => moment().startOf("day");
                switch (this.range) {
                    case "All":
                        break;
                    case "Today":
                        begin = today();
                        end = today().add(1, "days");
                        break;
                    case "Tomorrow":
                        begin = today().add(1, "days");
                        end = today().add(2, "days");
                        break;
                    case "This week":
                        begin = today().startOf("week");
                        end = today().endOf("week");
                        break;
                    case "This month":
                        begin = today().startOf("month");
                        end = today().endOf("month");
                        break;
                    case "Next 7 days":
                        begin = today().add(0, "days");
                        end = today().add(7, "days");
                        break;
                    case "Next 30 days":
                        begin = today().add(0, "days");
                        end = today().add(30, "days");
                        break;
                    case "All - upcoming":
                        begin = today();
                        break;
                    case "Yesterday":
                        begin = today().subtract(1, "days");
                        end = today();
                        break;
                    case "Last week":
                        begin = today()
                            .subtract(1, "weeks")
                            .startOf("week");
                        end = today()
                            .subtract(1, "weeks")
                            .endOf("week");
                        break;
                    case "Last month":
                        begin = today()
                            .subtract(1, "months")
                            .startOf("month");
                        end = today()
                            .subtract(1, "months")
                            .endOf("month");
                        break;
                    case "Last 7 days":
                        begin = today().subtract(7, "days");
                        end = today().subtract(0, "days");
                        break;
                    case "Last 30 days":
                        begin = today().subtract(30, "days");
                        end = today().subtract(0, "days");
                        break;
                    case "All - past":
                        end = today();
                        break;
                    case "Custom":
                        begin = this.startDate;
                        end = this.endDate;
                }

                this.startDate = begin;
                this.endDate = end;
            }
        },
        startDate: {
            handler(n, o) {
                if (!n || !o || moment(n).diff(moment(o))) {
                    this.$emit("input", [this.startDate ? moment(this.startDate).toDate() : null, this.endDate ? moment(this.endDate).toDate() : null]);
                }
            }
        },
        endDate: {
            handler(n, o) {
                if (!n || !o || moment(n).diff(moment(o))) {
                    this.$emit("input", [this.startDate ? moment(this.startDate).toDate() : null, this.endDate ? moment(this.endDate).toDate() : null]);
                }
            }
        }
    },
    methods: {
        datePickerChange(date, type) {
            if (type == "start") {
                this.startDate = date && moment(date);
            }
            if (type == "end") {
                this.endDate = date && moment(date);
            }
        }
    },
    mounted() {}
};
</script>
<style lang="less">
.date-range-picker {
    display: flex;

    .md-select {
        .md-select-value {
            line-height: 34px;
        }
    }

    .vue-date-picker {
        .datepickbox {
            input {
                font-size: 15px;
                border-bottom: none !important;
            }
            input::-webkit-input-placeholder {
                font-size: 12px;
                color: #9e9e9e;
            }
        }
    }

    .md-input-container {
        width: 120px;
        margin-right: 10px;
    }
    .range-container {
        width: 200px;
        display: flex;
        align-items: center;
        span {
            margin: 0 10px;
        }
    }
}
</style>

